'use strict';

var initialized = false,
    disableMinimize = false,
    commonElements = require('./common-elements'),
    util = require('./util'),
    tls = require('./tls'),
    $cache = {},
    options = {
        'startMinimize': {
            'desktop': 150,
            'mobile': 150
        },
        'calcDelay': 400
    };


/**
 * @private
 * @function
 * @description Initializes parameters for component
 */
function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}


/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.body = $('body');
    $cache.main = $('#main');
    $cache.header = $('.js-sticky_header');
    $cache.headerWrapper = $('.js-sticky_header-wrapper');
    $cache.utilityLinks = $('.js-utility_menu-link');
    $cache.utilityPopups = $('.js-utility_popup-item');
}


/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeEvents() {
    // Header utility menu
    $cache.utilityLinks.on('click touchstart', function(e) {
        e.preventDefault();
        var that = this,
            isClosed = !$(this).hasClass('active');

        closeUtilityPopups();

        if (isClosed) {
            if ($(this).data('scroll-top')) {
                util.scrollBrowser(0, 400, function() {
                    $cache.body.removeClass('js-header_fixed');
                    $cache.document.trigger('header.calculatelimit', {
                        container: $cache.utilityPopups.filter($(that).data('utility-popup'))
                    });
                });
            }
            openUtilityPopup(that); // open popup without any time delays for correct autofocusing when search icon clicked

            // for devices with iOS to display keyboard a focusing on field should be directly in function that is binded on click/touch event
            if (util.isIOS() && ($(that).data('utility-popup') == '.b-header-search')) {
                $cache.utilityPopups.filter('.b-header-search').find('#q').focus();
            }
        }
    });

    // Header cookie messages
    $('.js-cookies_message').each(function() {
        var that = $(this),
            cookieTTL = 60 * 24,
            cookieName = that.data('cookieName');

        if (!tls.getCookie(cookieName)) {
            that.addClass('js-showed');
            if (cookieName == 'hideCookiesAcceptance') {
                cookieTTL = 60 * 24 * 365;
                tls.setCookie(cookieName, true, cookieTTL);
            }
            that.on('click', '.js-close', function(e) {
                e.preventDefault();
                tls.setCookie(cookieName, true, cookieTTL);
                $cache.headerWrapper.height($cache.headerWrapper.outerHeight() - that.outerHeight());
                that.addClass('js-closed');
            });
        }
    });

    // Update sticky top value when header height is changed
    util.watchProperty($cache.header, 'clientHeight', function(e, oldHeight, newHeight) {
        $('.js-sticky-refinements.is_stuck')
            .css('top', newHeight + 'px')
            .stick_in_parent({
                offset_top: newHeight
            })
            .on("sticky_kit:stick", function(e) {
                $(e.target).css('top', newHeight + 'px');
            });
    });

    // Update sticky top value when sticky bar is reinited
    $('.js-sticky-refinements').on("sticky_kit:stick", function(e) {
        $(e.target).css('top', $cache.header.outerHeight());
    });

    initHeader();
    initMinimizeHeader();
    menuToggle();
}

//Minimize header while scroll
function initHeader() {
    $cache.headerWrapper.height($cache.header.outerHeight());
    // Remove additional space when page is loaded
    $cache.window.on('load', function() {
        !$cache.body.attr('class').match(/js-header_minimize|js-utility_opened/) && $cache.headerWrapper.height($cache.header.outerHeight());
    });
    $cache.body.addClass('js-header_fixed');
}


// Minimize header while scroll
function initMinimizeHeader() {
    var startMinimize = util.isMobile() ? options.startMinimize.mobile : options.startMinimize.desktop,
        limitTop = startMinimize,
        currentTop = 0,
        maxTop = $cache.document.height() - $cache.window.height(),
        timer = util.getTimer();

    $cache.window.on('load resize infinitescroll.loaded', function() {
        maxTop = $cache.document.height() - $cache.window.height();
    });

    $cache.document
        .on('header.openpopup searchsuggest.open header.calculatelimit', function(event, customEvent) {
            if (!$cache.body.hasClass('js-header_fixed')) {
                // Check if it's minicart
                var $container = $(customEvent.container).find('.js-mini_cart-content') || $(customEvent.container);
                $container = $container.length > 0 ? $container : $(customEvent.container);
                var containerHeight = $container.height();
                if ($container.hasClass('b-header-search')) {
                    $container.find('#q').focus();
                    containerHeight += $container.find('#search-suggestions').height();
                }
                var windowHeight = $cache.window.height();
                limitTop = containerHeight + $container.offset().top;

                // Calculate scroll position when utility popup should be hidden
                if (!$container.hasClass('js-main_navigation')) {
                    if (limitTop > windowHeight) {
                        limitTop -= windowHeight / 2;
                    } else if (limitTop > windowHeight / 2) {
                        limitTop = windowHeight / 2;
                    }
                } else {
                    util.watchProperty($container, 'clientHeight', function(e, oldHeight, newHeight) {
                        limitTop = $container.height() + $container.offset().top;
                    }, 200);
                }
            } else {
                limitTop = startMinimize;
            }
        })
        .on('minicart.shown', function(event, customEvent) {
            $(customEvent.container).next('.js-utility_menu-link:not(.active)').click();
        })
        .on('minicart.hidden', function(event, customEvent) {
            $(customEvent.container).next('.js-utility_menu-link.active').click();
        })
        .on('header.closepopup', function() {
            limitTop = startMinimize;
            util.stopWatchProperty($('.js-main_navigation'), 'clientHeight');
        })
        .on('searchsuggest.close', function() {
            limitTop = startMinimize;
            $cache.window.scroll();
        });

    minimizeHeaderCheck();
    if (util.isMobile()) {
        $cache.header
            .on('focus', 'select', function() {
                disableMinimize = true;
            })
            .on('blur', 'select', function() {
                // Set timeout to avoid minimizing while keyboard is hiding
                setTimeout(function() {
                    disableMinimize = false;
                }, 1000);
            });

        var canScroll = true;
        $cache.window.on('scroll touchmove touchend', function(e) {
            if (canScroll) {
                canScroll = false;
                setTimeout(function() {
                    minimizeHeaderCheck();
                    canScroll = true;
                }, 100);
            }
        });
    } else {
        $cache.window.on('scroll', function(e) {
            minimizeHeaderCheck();
        });
    }

    // Adding additional class to body when header should be minimized
    function minimizeHeaderCheck() {
        currentTop = $cache.window.scrollTop();

        if (Math.abs(currentTop - limitTop) > 10) { //Prevent header minimizing for micro scrolling
            var enabledHeader = $cache.body.hasClass('js-utility_opened') && $cache.body.hasClass('js-header_fixed');
            if (currentTop < startMinimize) {
                minimizeHeader(false);
                enabledHeader && (limitTop = startMinimize);
            } else {
                minimizeHeader(((currentTop > limitTop) || (currentTop > maxTop)) && !enabledHeader && !disableMinimize);
                $cache.body.hasClass('js-header_fixed') && (limitTop = currentTop);
            }
        }
    }
}

// Adding additional class to body when header should be minimized
function minimizeHeader(minimize) {
    if (minimize) {
      var $activePopups = $cache.utilityPopups.filter('.active');

        if (!$cache.body.hasClass('js-header_minimized')) {
            $cache.body.addClass('js-header_minimized');

            if (($activePopups.is('.b-countries') || $activePopups.is('.b-languages')) && $activePopups.closest('.b-footer_extra-menu')[0] && util.elementInViewport($activePopups[0])) {
              return false;
            } else {
              closeUtilityPopups();
            }
        }
    } else {
        if ($cache.body.hasClass('js-header_minimized')) {
            $cache.body.removeClass('js-header_minimized');
        }
    }
}

// Open utility popup
function openUtilityPopup(element, disableHeader) {
    var popupSelector = $(element).data('utility-popup'),
        $popup = $cache.utilityPopups.filter(popupSelector),
        $similarLinks = $cache.utilityLinks.filter(function() {
            return $(this).data("utility-popup") == popupSelector;
        });

    commonElements.loadLazyIframes($popup);

    disableHeader && $cache.body.removeClass('js-header_fixed');
    $cache.body.removeClass('js-header_minimized').addClass('js-utility_opened');
    $similarLinks.addClass('active');
    $popup.addClass('active');

    $cache.main.on('click.utility', function() {
        closeUtilityPopups();

        if (!util.elementInViewport($cache.headerWrapper[0])) {
            minimizeHeader(true);
        }
    });

    if (popupSelector != '#mini-cart') {
        if ($(element).data('slide-right')) {
            $popup.show("slide", { direction: "right" }, 500);
        } else {
            $popup.slideDown();
        }   
    }

    setTimeout(function() {
        $cache.document.trigger('header.openpopup', {
            container: $popup
        });
    }, options.calcDelay);
}

// Closing all utility popups
function closeUtilityPopups() {
    var $activePopups = $cache.utilityPopups.filter('.active');

    if ($activePopups.size() > 0) {
        $activePopups.find(':focus, iframe').blur();

        $cache.utilityLinks.removeClass('active');
        $cache.utilityPopups.removeClass('active');
        $cache.body.removeClass('js-utility_opened').addClass('js-header_fixed');

        $cache.main.off('click.utility');

        if (!$activePopups.is('#mini-cart')) {
            $activePopups.each(function(index,element) {
                if ($(element).data('slide-right')) {
                    $(element).hide('slide', {direction: 'right'}, 500);
                } else {
                    $(element).slideUp();
                }
            });
        }

        $cache.document.trigger('header.closepopup');
    }
}

// main menu dropdown toggle
function menuToggle() {
    $('.js-toggled_menu .menu-item-toggle').on('click', function(e) {
        e.preventDefault();
        var toggleItem = this,
            $parentLi = $(e.target).closest('li, .js-navigation-item'),
            $siblings = $parentLi.siblings('li, .js-navigation-item'),
            toggleOptions = {},
            toggleEffect = $(e.target).data('toggle-effect') || 'blind';

        if ($(e.target).data('toggle-direction')) {
            toggleOptions.direction = $(e.target).data('toggle-direction');
            toggleOptions.duration = 300;
        }

        if ($parentLi.hasClass('js-navigation-item')) {
            $siblings = $siblings.add($parentLi.parent().siblings().children('.js-navigation-item'));
        }

        $siblings
            .removeClass('active')
            .children('.js-main_navigation-dropdown').slideUp()
            .end().children('.menu-item-toggle').removeClass('active');


        $parentLi.toggleClass('active')
            .children('.js-main_navigation-dropdown').toggle(toggleEffect, toggleOptions, function() {
            if ($parentLi.hasClass('active') && !util.elementInViewport(toggleItem)) {
                disableMinimize = true;
                util.scrollBrowser($parentLi.offset().top, 200, function() {
                    disableMinimize = false;
                });
            }
        });

        $(e.target).toggleClass('active');
    });
}

module.exports = {
    init: function(params) {
        if (initialized) {
            return;
        }
        initializeParams(params);
        initializeCache();
        initializeEvents();
        initialized = true;
    },
    closeUtilityPopups: closeUtilityPopups
}
