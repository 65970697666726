var gatewayMerchantId = $('#googleData').data('merchant-account');
var pageRef = $('#googleData').data('pageref');

var merchantInfo = {
    // merchantId: "12345678910111213141", // Required for PRODUCTION. Remove this field in TEST. Your Google Merchant ID as described in https://developers.google.com/pay/api/web/guides/test-and-deploy/deploy-production-environment obtain-your-merchantID    
    //merchantName: "Philipp Plein" // Optional. The name that appears in the payment sheet.
};

var environment = '';

if ($('#googleData').data('environment') && $('#googleData').data('environment').toUpperCase() === 'TEST') {
    environment = 'TEST';
    merchantInfo.merchantId = $('#googleData').data('merchant-identifier');
    merchantInfo.merchantName = $('#googleData').data('merchant-name');
} else if ($('#googleData').data('environment') && $('#googleData').data('environment').toUpperCase() === 'LIVE') {
    environment = 'PRODUCTION';
    merchantInfo.merchantId = $('#googleData').data('merchant-identifier');
    merchantInfo.merchantName = $('#googleData').data('merchant-name');
}

var defaultShippingOptions;
var shippingCosts = {};
var googleTransactionInfo;
var allowedCountryCodes = [];

/**
 * An initialized google.payments.api.PaymentsClient object or null if not yet set
 *
 * @see {@link getGooglePaymentsClient}
 */
let paymentsClient = null;

/**
 * Define the version of the Google Pay API referenced when creating your
 * configuration
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-object PaymentDataRequest in PaymentDataRequest}
 */
const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
};

var shippingPromoFunction = null;
if ($('#googleData').data('shipping-function') == 'AMOUNT') {
    shippingPromoFunction = staticDiscount;
} else if ($('#googleData').data('shipping-function') == 'PERCENTAGE') {
    shippingPromoFunction = staticDiscount;
} else if ($('#googleData').data('shipping-function') == 'FREE') {
    shippingPromoFunction = freeDiscount;
}

var orderPromoFunction = null;
if ($('#googleData').data('order-function') == 'AMOUNT') {
    orderPromoFunction = staticDiscount;
} else if ($('#googleData').data('order-function') == 'PERCENTAGE') {
    orderPromoFunction = staticDiscount;
} else if ($('#googleData').data('order-function') == 'FREE') {
    orderPromoFunction = freeDiscount;
}

const validPromoCodes = {
    SHIPPINGPROMO: {
        code: 'SHIPPINGPROMO',
        description: 'Shipping Discount',
        function: shippingPromoFunction,
        value: $('#googleData').data('shipping-discount') // value should be negative for a discount
    },
    ORDERPROMO: {
        code: 'ORDERPROMO',
        description: 'Order Discount',
        function: orderPromoFunction,
        value: $('#googleData').data('order-discount') // value should be negative for a discount
    }
}

var validRedemptionCodes = new Set();
if (validPromoCodes.SHIPPINGPROMO.value !== "0.0") {
    validRedemptionCodes.add(validPromoCodes.SHIPPINGPROMO.code)
}
if (validPromoCodes.ORDERPROMO.value !== "0.0") {
    validRedemptionCodes.add(validPromoCodes.ORDERPROMO.code)
}


/**
 * Card networks supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects CardParameters}
 * @todo confirm card networks supported by your site and gateway
 */
const allowedCardNetworks = ["AMEX", "DISCOVER", "JCB", "MASTERCARD", "VISA"];

/**
 * Card authentication methods supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects CardParameters}
 * @todo confirm your processor supports Android device tokens for your
 * supported card networks
 */
const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

/**
 * Identify your gateway and your site's gateway merchant identifier
 *
 * The Google Pay API response will return an encrypted payment method capable
 * of being charged by a supported gateway after payer authorization
 *
 * @todo check with your gateway on the parameters to pass
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects gateway}
 */
const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
        'gateway': 'adyen',
        'gatewayMerchantId': gatewayMerchantId
    }
};

/**
 * Describe your site's support for the CARD payment method and its required
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects CardParameters}
 */
const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks: allowedCardNetworks
    }
};

/**
 * Describe your site's support for the CARD payment method including optional
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects CardParameters}
 */
const cardPaymentMethod = Object.assign(
    {},
    baseCardPaymentMethod,
    {
        tokenizationSpecification: tokenizationSpecification
    }
);

/**
 * Configure your site's support for payment methods supported by the Google Pay
 * API.
 *
 * Each member of allowedPaymentMethods should contain only the required fields,
 * allowing reuse of this base request when determining a viewer's ability
 * to pay and later requesting a supported payment method
 *
 * @returns {object} Google Pay API version, payment methods supported by the site
 */
function getGoogleIsReadyToPayRequest() {
    return Object.assign(
        {},
        baseRequest,
        {
            allowedPaymentMethods: [baseCardPaymentMethod]
        }
    );
}

/**
 * Configure support for the Google Pay API
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects PaymentDataRequest}
 * @returns {object} PaymentDataRequest fields
 */
function getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = merchantInfo;

    if (pageRef == "cart") {
        paymentDataRequest.callbackIntents = ["SHIPPING_ADDRESS", "SHIPPING_OPTION", "PAYMENT_AUTHORIZATION"];
        paymentDataRequest.shippingAddressRequired = true;
        paymentDataRequest.shippingAddressParameters = getGoogleShippingAddressParameters();
        paymentDataRequest.shippingOptionRequired = true;
        paymentDataRequest.emailRequired = true;
    } else if (pageRef == "checkout") {
        paymentDataRequest.callbackIntents = ["PAYMENT_AUTHORIZATION"];
    }

    return paymentDataRequest;
}

/**
 * Return an active PaymentsClient or initialize
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client PaymentsClient constructor}
 * @returns {google.payments.api.PaymentsClient} Google Pay API client
 */
function getGooglePaymentsClient() {
    if (paymentsClient === null) {
        if (pageRef == "cart") {
            paymentsClient = new google.payments.api.PaymentsClient({
                environment: environment,
                merchantInfo: merchantInfo,
                paymentDataCallbacks: {
                    onPaymentAuthorized: onPaymentAuthorized,
                    onPaymentDataChanged: onPaymentDataChanged
                }
            });
        } else if (pageRef == "checkout") {
            paymentsClient = new google.payments.api.PaymentsClient({
                environment: environment,
                merchantInfo: merchantInfo,
                paymentDataCallbacks: {
                    onPaymentAuthorized: onPaymentAuthorized
                }
            });
        }
    }
    return paymentsClient;
}


function onPaymentAuthorized(paymentData) {
    return new Promise(function (resolve, reject) {

        // handle the response
        processPayment(paymentData)
            .then(function () {
                resolve({ transactionState: 'SUCCESS' });
            })
            .catch(function () {
                resolve({
                    transactionState: 'ERROR',
                    error: {
                        intent: 'PAYMENT_AUTHORIZATION',
                        message: 'Insufficient funds, try again. Next attempt should work',
                        reason: 'PAYMENT_DATA_INVALID'
                    }
                });
            });
    });
}

/**
 * These functions handle adding valid promo codes to the payment sheet
 * as well as adjusting the display items to match.
 *
 * To add a new promo code, create a new function per this template
 * and define it as valid in the onPaymentDataChanged function.
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentDataRequestUpdate PaymentDataRequestUpdate}
 * @param {string} redemptionCode string representing the promo code to apply
 * @param {string} description string representing the description to show
 * @param {int} discountPercentage int representing the percentage to discount.
 * Please note the discount value should be negative. Ex: -20 = 20% discount.
 * @param {object} PaymentDataRequestUpdate object representing
 * the current state of the payment data request.
 * @returns {object} PaymentDataRequestUpdate object to update the
 * payment sheet with new transaction info and offer data.
 */

function percentageDiscount(promoParameters, paymentDataRequestUpdate) {
    // set variables
    let originalTransactionInfo = getGoogleTransactionInfo();
    /* because this promo code calculates a % of original prices,
     * we need to get the original transaction info */
    let newTransactionInfo = paymentDataRequestUpdate.newTransactionInfo;
    let discount = 0;

    // update promo code and description
    paymentDataRequestUpdate.newOfferInfo.offers.push({
        redemptionCode: promoParameters['code'],
        description: promoParameters['description']
    });

    // calculate discount (from original transaction items only)
    originalTransactionInfo.displayItems.forEach(function (displayItem) {
        if (displayItem.type != "TAX") {
            discount += parseFloat(displayItem.price) * parseFloat(promoParameters['value']) * 0.01;
        }

    });

    // add displayItem with new discount
    newTransactionInfo.displayItems.push({
        label: promoParameters['description'],
        price: discount.toFixed(2),
        type: 'LINE_ITEM'
    });

    return paymentDataRequestUpdate;
}

function staticDiscount(promoParameters, paymentDataRequestUpdate) {
    // set variables
    let newTransactionInfo = paymentDataRequestUpdate.newTransactionInfo;

    // update promo code and description
    paymentDataRequestUpdate.newOfferInfo.offers.push({
        redemptionCode: promoParameters['code'],
        description: promoParameters['description']
    });

    // add displayItem with new discount
    newTransactionInfo.displayItems.push({
        label: promoParameters['description'],
        price: parseFloat(promoParameters['value']).toFixed(2),
        type: 'LINE_ITEM'
    });

    let totalPrice = 0.00;
    newTransactionInfo.displayItems.forEach(displayItem => {
        if (displayItem.type != "TAX") {
            totalPrice += parseFloat(displayItem.price)
        }
    });
    newTransactionInfo.totalPrice = totalPrice.toString();

    return paymentDataRequestUpdate;
}

function freeDiscount(promoParameters, paymentDataRequestUpdate) {
    // set variables
    let newTransactionInfo = paymentDataRequestUpdate.newTransactionInfo;

    // update promo code and description
    paymentDataRequestUpdate.newOfferInfo.offers.push({
        redemptionCode: promoParameters['code'],
        description: promoParameters['description']
    });

    // add displayItem with new discount
    newTransactionInfo.displayItems.push({
        label: promoParameters['description'],
        price: parseFloat(promoParameters['value']).toFixed(2),
        type: 'LINE_ITEM'
    });

    let totalPrice = 0.00;
    newTransactionInfo.displayItems.forEach(displayItem => {
        if (displayItem.type != "TAX") {
            totalPrice += parseFloat(displayItem.price)
        }
    });
    newTransactionInfo.totalPrice = totalPrice.toString();

    return paymentDataRequestUpdate;
}

/**
 * Handles dynamic buy flow shipping address and shipping options callback intents.
 *
 * @param {object} itermediatePaymentData response from Google Pay API a shipping address or shipping option is selected in the payment sheet.
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects IntermediatePaymentData object reference}
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects PaymentDataRequestUpdate}
 * @returns Promise<{object}> Promise of PaymentDataRequestUpdate object to update the payment sheet.
 */
function onPaymentDataChanged(intermediatePaymentData) {
    return new Promise(function (resolve, reject) {

        let redemptionCodes = new Set();
        let shippingAddress = intermediatePaymentData.shippingAddress;
        let shippingOptionData = intermediatePaymentData.shippingOptionData;
        let paymentDataRequestUpdate = {};
        // ensure that promo codes set is unique
        redemptionCodes = new Set(validRedemptionCodes);

        if (intermediatePaymentData.callbackTrigger == "INITIALIZE" || intermediatePaymentData.callbackTrigger == "SHIPPING_ADDRESS") {
            if (shippingAddress.administrativeArea == "NJ") {
                paymentDataRequestUpdate.error = getGoogleUnserviceableAddressError();
            } else {
                paymentDataRequestUpdate.newShippingOptionParameters = getGoogleDefaultShippingOptions();
                let selectedShippingOptionId = paymentDataRequestUpdate.newShippingOptionParameters.defaultSelectedOptionId;
                paymentDataRequestUpdate.newTransactionInfo = calculateNewTransactionInfo(selectedShippingOptionId);
                paymentDataRequestUpdate.newOfferInfo = {};
                paymentDataRequestUpdate.newOfferInfo.offers = [];
                for (redemptionCode of redemptionCodes) {
                    if (validPromoCodes[redemptionCode]) {
                        paymentDataRequestUpdate = validPromoCodes[redemptionCode].function(validPromoCodes[redemptionCode], paymentDataRequestUpdate);
                    } else {
                        paymentDataRequestUpdate.error = getGoogleOfferInvalidError(redemptionCode);
                    }
                }
            }
        } else if (intermediatePaymentData.callbackTrigger == "SHIPPING_OPTION") {
            paymentDataRequestUpdate.newTransactionInfo = calculateNewTransactionInfo(shippingOptionData.id);
            paymentDataRequestUpdate.newOfferInfo = {};
            paymentDataRequestUpdate.newOfferInfo.offers = [];
            for (redemptionCode of redemptionCodes) {
                if (validPromoCodes[redemptionCode]) {
                    paymentDataRequestUpdate = validPromoCodes[redemptionCode].function(validPromoCodes[redemptionCode], paymentDataRequestUpdate);
                } else {
                    paymentDataRequestUpdate.error = getGoogleOfferInvalidError(redemptionCode);
                }
            }
        }

        resolve(paymentDataRequestUpdate);
    });
}

/**
 * Helper function to create a new TransactionInfo object.

 * @param string shippingOptionId respresenting the selected shipping option in the payment sheet.
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects TransactionInfo}
 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
 */
function calculateNewTransactionInfo(shippingOptionId) {
    let newTransactionInfo = getGoogleTransactionInfo();

    let shippingCost = getShippingCosts()[shippingOptionId];
    newTransactionInfo.displayItems.push({
        type: "LINE_ITEM",
        label: "Shipping cost",
        price: shippingCost,
        status: "FINAL"
    });

    let totalPrice = 0.00;
    newTransactionInfo.displayItems.forEach(displayItem => {
        if (displayItem.type != "TAX") {
            totalPrice += parseFloat(displayItem.price)
        }
    });
    newTransactionInfo.totalPrice = totalPrice.toString();

    return newTransactionInfo;
}

/**
 * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
 *
 * Display a Google Pay payment button after confirmation of the viewer's
 * ability to pay.
 */
function onGooglePayLoaded() {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
        .then(function (response) {
            if (response.result) {
                addGooglePayButton();
                // @todo prefetch payment data to improve performance after confirming site functionality
                // prefetchGooglePaymentData();
            }
        })
        .catch(function (err) {
            // show error in developer console for debugging
            console.error(err);
        }
        );
}

/**
 * Add a Google Pay purchase button alongside an existing checkout button
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects ButtonOptions options}
 * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines Google Pay brand guidelines}
 */
function addGooglePayButton() {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({ onClick: onGooglePaymentButtonClicked, buttonColor: 'black', buttonType: 'pay', buttonSizeMode: 'fill' });
    document.getElementById('container').appendChild(button);
}

/**
 * Provide Google Pay API with a payment amount, currency, and amount status
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects TransactionInfo}
 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
 */
function getGoogleTransactionInfo() {
    return JSON.parse(JSON.stringify(googleTransactionInfo));
}

/**
 * Provide a key value store for shippping options.
 */
function getShippingCosts() {
    return shippingCosts;
}

/**
 * Provide Google Pay API with shipping address parameters when using dynamic buy flow.
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects ShippingAddressParameters}
 * @returns {object} shipping address details, suitable for use as shippingAddressParameters property of PaymentDataRequest
 */
function getGoogleShippingAddressParameters() {
    return {
        allowedCountryCodes: allowedCountryCodes,
        phoneNumberRequired: true
    };
}

/**
 * Provide Google Pay API with shipping options and a default selected shipping option.
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects ShippingOptionParameters}
 * @returns {object} shipping option parameters, suitable for use as shippingOptionParameters property of PaymentDataRequest
 */
function getGoogleDefaultShippingOptions() {
    defaultShippingOptions.defaultSelectedOptionId = $('.js-delivery-option:checked').val() || defaultShippingOptions.defaultSelectedOptionId;
    return defaultShippingOptions;
}

/**
 * Provide Google Pay API with a payment data error.
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects PaymentDataError}
 * @returns {object} payment data error, suitable for use as error property of PaymentDataRequestUpdate
 */
function getGoogleUnserviceableAddressError() {
    return {
        reason: "SHIPPING_ADDRESS_UNSERVICEABLE",
        message: "Cannot ship to the selected address",
        intent: "SHIPPING_ADDRESS"
    };
}

/**
 * Provide Google Pay API with an invalid offer error.
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentDataError PaymentDataError}
 * @returns {object} payment data error, suitable for use as error property of PaymentDataRequestUpdate
 */
function getGoogleOfferInvalidError(redemptionCode) {
    return {
        reason: 'OFFER_INVALID',
        message: redemptionCode + ' is not a valid promo code.',
        intent: 'OFFER'
    };
}

/**
 * Prefetch payment data to improve performance
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client prefetchPaymentData}
 */
function prefetchGooglePaymentData() {
    const paymentDataRequest = getGooglePaymentDataRequest();
    // transactionInfo must be set but does not affect cache
    paymentDataRequest.transactionInfo = {
        totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
        currencyCode: 'USD'
    };
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.prefetchPaymentData(paymentDataRequest);
}



/**
 * Show Google Pay payment sheet when Google Pay payment button is clicked
 */
function onGooglePaymentButtonClicked() {
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.loadPaymentData(paymentDataRequest);
}

function handleErrorMessage(response) {
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show the message toast
    if ($('.googlepay-messages').length === 0) {
        $('body').append(
            '<div class="googlepay-messages"></div>'
        );
    }

    if ($('.googlepay-error-alert').length >= 1) {
        $('.googlepay-error-alert').remove();
    }

    $('.googlepay-messages').append(
        '<div class="alert ' + messageType + ' googlepay-error-alert text-center" role="alert">'
        + response.errorMessage
        + '</div>'
    );
}

/**
 * Process payment data returned by the Google Pay API
 *
 * @param {object} paymentData response from Google Pay API after user approves payment
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects PaymentData object reference}
 */
function processPayment(paymentData) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            // show returned data in developer console for debugging
            //console.log(paymentData);
            $.ajax({
                url: $('#googleData').data('url'),
                method: 'post',
                data: 'data=' + JSON.stringify(paymentData),
                dataType: 'json',
                success: function (data) {
                    if (data.resultCode === 'Authorised') {
                        if (data.redirectUrl) {
                            window.location.href = data.redirectUrl;
                        }
                    } else if (data.resultCode === 'RedirectShopper') {
                        // checkout.createFromAction(data.action).mount('#my-container');                        
                        if (data.continueUrl) {
                            window.location.href = data.continueUrl;
                        }
                    } else if (data.error) {
                        handleErrorMessage(data);
                    }
                },
                error: function (e) {

                }
            });

            resolve({});

        }, 3000);
    });
}

function waitForLoadElement(element) {
    var $element = $(element);
    if ($element && $element.length) {
        $.ajax({
            url: $('#googleData').data('shipment-url'),
            method: 'GET',
            success: function (data) {
                if (data.shipments && data.shipments.length && data.shipments[0].shippingMethods && data.shipments[0].shippingMethods.length) {
                    var shippingMethods = data.shipments[0].shippingMethods;
                    defaultShippingOptions = {
                        defaultSelectedOptionId: data.shipments[0].selectedShippingMethod || shippingMethods[0].ID,
                        shippingOptions: shippingMethods.map(function (shippingMethod) {
                            shippingCosts[shippingMethod.ID] = shippingMethod.shippingCost.toString();
                            return {
                                id: shippingMethod.ID,
                                label: shippingMethod.displayName,
                                description: shippingMethod.description
                            };
                        })
                    };
                }

                if (data.googleTransactionInfo) {
                    googleTransactionInfo = data.googleTransactionInfo;
                }

                if (data.allowedCountryCodes) {
                    allowedCountryCodes.push(data.allowedCountryCodes);
                }

                onGooglePayLoaded();
            },
            error: function (data) {

            }
        });
    } else {
        setTimeout(waitForLoadElement, 100);
    }
}

module.exports = {
    waitForLoadElement: waitForLoadElement
};